export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "dashboard",
    Permissions: [1002, 1102, 1202, 1302, 1402, 1502, 1602],
  },
  /*   {
    label: "MANAGE",
    type: "label",
    Permissions: [1002, 1102, 1202, 1302],
  }, */
  {
    name: "Administration",
    icon: "security",
    Permissions: [1002, 1102, 1202, 1302],
    children: [
      {
        name: "Users",
        iconText: "SI",
        path: "/Users/List",
        Permissions: [1102],
      },
      {
        name: "DahiyaMitra",
        iconText: "SU",
        path: "/DahiyaMitra/List",
        Permissions: [1202],
      },
      {
        name: "CLIA M-32 Agents",
        iconText: "CA",
        path: "/CLIA/List",
        Permissions: [1502],
      },

      {
        name: "Inputs",
        iconText: "SU",
        path: "/Inputs",
        Permissions: [1702],
      },
    ],
  },
  /* { label: "PROJECTS", type: "label", Permissions: [0] }, */
  {
    name: "Application",
    icon: "assignment",
    Permissions: [1302, 1402, 1502, 1602],
    children: [
      {
        name: "Reference",
        iconText: "RF",
        path: "/References/ListReference",
        Permissions: [1402],
      },
      {
        name: "Prospect",
        iconText: "PR",
        path: "/Prospects/ListProspects",
        Permissions: [1302],
      },
      {
        name: "Policy",
        iconText: "PR",
        path: "/Policy/List",
        Permissions: [1602],
      },
      {
        name: "CLIA TEAM DAHIYA",
        iconText: "CL",
        path: "/CLIAProspect/ListCLIAReference",
        Permissions: [1502],
      },
    ],
  },
  /* { label: "SERVICES", type: "label", Permissions: [0] }, */
  {
    name: "Account",
    icon: "assignment",
    Permissions: [1302, 1402, 1502, 1602],
    children: [
      {
        name: "Account Book",
        iconText: "RF",
        path: "/AccountBook",
        Permissions: [1402],
      },
    ],
  },
  {
    name: "LIC Agency Work",
    icon: "work",
    Permissions: [9994, 9995, 9996, 9997, 9998, 1902, 1802],
    children: [
      {
        name: "Policy Services",
        iconText: "CL",
        path: "/Services/PendingPolicyServices",
        Permissions: [1902],
      },
      {
        name: "LIC BOND",
        iconText: "LB",
        path: "/Services/Bond",
        Permissions: [1902],
      },
      {
        name: "E-NACH",
        iconText: "EN",
        path: "/Services/Enach",
        Permissions: [1902],
      },
      {
        name: "Minor DAB",
        iconText: "MD",
        path: "/Services/MinorDAB",
        Permissions: [1902],
      },
      {
        name: "Minor Nomination",
        iconText: "CL",
        path: "/Services/MinorNomination",
        Permissions: [1902],
      },
      {
        name: "SB Bonus",
        iconText: "SBL",
        path: "/SBBonusList",
        Permissions: [1802],
      },
      {
        name: "LIC Maturity",
        iconText: "LML",
        path: "/LICMaturityList",
        Permissions: [1802],
      },
    ],
  },
  {
    name: "Premium",
    icon: "list",
    Permissions: [1802],
    children: [
      {
        name: "PREMIUM POINT",
        iconText: "LB",
        path: "/PremiumPoint/list",
        Permissions: [1802],
      },
      {
        name: "Current Month Due List",
        iconText: "CML",
        path: "/CurrentMonthDueList",
        Permissions: [1802],
      },
      {
        name: "Outstanding Due List",
        iconText: "ODL",
        path: "/OutstandingDueList",
        Permissions: [1802],
      },
      {
        name: "Lapsed Due List",
        iconText: "LDL",
        path: "/LapsedPolicyDueList",
        Permissions: [1802],
      },
    ],
  },
  {
    name: "Other",
    icon: "Calender",
    Permissions: [2101, 2201, 2301],
    children: [
      {
        name: "Calendar",
        iconText: "CR",
        path: "/Calendar",
        Permissions: [2301],
      },
      {
        name: "Greeting",
        iconText: "GR",
        path: "/Greeting",
        Permissions: [2201],
      },
      {
        name: "Follow ups",
        iconText: "FL",
        path: "/Followups",
        Permissions: [2101],
      },
    ],
  },
];
/* 
{ label: "Components", type: "label" },
{
  name: "Components",
  icon: "favorite",
  badge: { value: "30+", color: "secondary" },
  children: [
    { name: "Auto Complete", path: "/material/autocomplete", iconText: "A" },
    { name: "Buttons", path: "/material/buttons", iconText: "B" },
    { name: "Checkbox", path: "/material/checkbox", iconText: "C" },
    { name: "Dialog", path: "/material/dialog", iconText: "D" },
    {
      name: "Expansion Panel",
      path: "/material/expansion-panel",
      iconText: "E",
    },
    { name: "Form", path: "/material/form", iconText: "F" },
    { name: "Icons", path: "/material/icons", iconText: "I" },
    { name: "Menu", path: "/material/menu", iconText: "M" },
    { name: "Progress", path: "/material/progress", iconText: "P" },
    { name: "Radio", path: "/material/radio", iconText: "R" },
    { name: "Switch", path: "/material/switch", iconText: "S" },
    { name: "Slider", path: "/material/slider", iconText: "S" },
    { name: "Snackbar", path: "/material/snackbar", iconText: "S" },
    { name: "Table", path: "/material/table", iconText: "T" },
  ],
},
{
  name: "Charts",
  icon: "trending_up",
  children: [{ name: "Echarts", path: "/charts/echarts", iconText: "E" }],
}, */
