import React from "react";

import { Routes, Route } from "react-router-dom";
import NotFound from "./views/sessions/NotFound";
import Layout from "./Layout";
import JwtLogin from "./views/sessions/JwtLogin";
import PersistLogin from "./views/sessions/PersistLogin";
import RequireAuth from "./views/sessions/RequireAuth";
import Main from "./pages/main";
import Analytics from "./views/dashboard/Analytics";

const DahiyaMitra = React.lazy(() => import("./pages/DahiyaMitra"));
const Customers = React.lazy(() => import("./pages/Customers"));
const Inputs = React.lazy(() => import("./pages/Inputs"));
const Prospects = React.lazy(() => import("./pages/Prospects"));
const Users = React.lazy(() => import("./pages/Users"));
const AddCustomer = React.lazy(() => import("./pages/Customers/AddCustomer"));
const ListCustomer = React.lazy(() => import("./pages/Customers/ListCustomer"));
const ImportCustomer = React.lazy(() =>
  import("./pages/Customers/ImportCustomer")
);
const DeletedCustomer = React.lazy(() =>
  import("./pages/Customers/DeletedCustomer")
);
const AddDahiyaMitra = React.lazy(() =>
  import("./pages/DahiyaMitra/AddDahiyaMitra")
);
const DeletedDahiyaMitra = React.lazy(() =>
  import("./pages/DahiyaMitra/DeletedDahiyaMitra")
);
const ListDahiyaMitra = React.lazy(() =>
  import("./pages/DahiyaMitra/ListDahiyaMitra")
);
const ViewDahiyaMitra = React.lazy(() =>
  import("./pages/DahiyaMitra/ViewDahiyaMitra")
);
const ViewCustomer = React.lazy(() => import("./pages/Customers/ViewCustomer"));
const AddUser = React.lazy(() => import("./pages/Users/AddUser"));
const DisabledUser = React.lazy(() => import("./pages/Users/DisabledUser"));
const ViewUser = React.lazy(() => import("./pages/Users/ViewUser"));
const ListUsers = React.lazy(() => import("./pages/Users/ListUsers"));
const NewProspect = React.lazy(() => import("./pages/Prospects/NewProspect"));
const ViewProspect = React.lazy(() => import("./pages/Prospects/ViewProspect"));
const ImportProspect = React.lazy(() =>
  import("./pages/Prospects/ImportProspect")
);
const DeletedProspect = React.lazy(() =>
  import("./pages/Prospects/DeletedProspect")
);
const NewReference = React.lazy(() =>
  import("./pages/References/NewReference")
);
const CalendarView = React.lazy(() => import("./pages/Calender"));
const ListReference = React.lazy(() =>
  import("./pages/References/ListReference")
);
const ImportReference = React.lazy(() =>
  import("./pages/References/ImportReference")
);
const ViewReference = React.lazy(() =>
  import("./pages/References/EditReference")
);
const ListFamilyMembers = React.lazy(() =>
  import("./pages/Prospects/ListFamilyMembers")
);
const Greetings = React.lazy(() => import("./pages/Greetings"));
const References = React.lazy(() => import("./pages/References"));
const DeletedReference = React.lazy(() =>
  import("./pages/References/DeletedReference")
);
const ConceptDiscussed = React.lazy(() =>
  import("./pages/Prospects/ConceptDiscussion")
);
const ListProspects = React.lazy(() =>
  import("./pages/Prospects/ListProspects")
);
const FollowupList = React.lazy(() => import("./pages/Prospects/FollowupList"));
const AgencyWorks = React.lazy(() => import("./pages/AgencyWorks"));
const CLIA = React.lazy(() => import("./pages/CLIA"));
const AddCLIA = React.lazy(() => import("./pages/CLIA/AddCLIAAgent"));
const ListCLIA = React.lazy(() => import("./pages/CLIA/ListCLIAAgent"));
const DeletedCLIA = React.lazy(() => import("./pages/CLIA/DeletedCLIAAgent"));
const ViewCLIA = React.lazy(() => import("./pages/CLIA/ViewCLIAAgent"));
const Policy = React.lazy(() => import("./pages/Policy"));
const AddPolicy = React.lazy(() => import("./pages/Policy/AddPolicy"));
const ListPolicy = React.lazy(() => import("./pages/Policy/ListPolicy"));
const DeletedPolicy = React.lazy(() => import("./pages/Policy/DeletedPolicy"));
const EditPolicy = React.lazy(() => import("./pages/Policy/ViewPolicy"));
const Services = React.lazy(() => import("./pages/Services"));
const Bond = React.lazy(() => import("./pages/Services/Bond"));
const ENach = React.lazy(() => import("./pages/Services/ENach"));
const MinorNomination = React.lazy(() =>
  import("./pages/Services/MinorNomination")
);
const MinorDAB = React.lazy(() => import("./pages/Services/MinorDAB"));
const PolicyServices = React.lazy(() =>
  import("./pages/Services/PolicyServices")
);
const UpdateBondStatus = React.lazy(() =>
  import("./pages/Services/UpdateBondStatus")
);
const UpdateENachStatus = React.lazy(() =>
  import("./pages/Services/UpdateENachStatus")
);
const ListPendingUpdatePolicy = React.lazy(() =>
  import("./pages/Policy/ListPendingUpdatePolicy")
);
const UpdateNomination = React.lazy(() =>
  import("./pages/Services/UpdateNomination")
);
const UpdateDAB = React.lazy(() => import("./pages/Services/UpdateDAB"));
const AddPolicyService = React.lazy(() =>
  import("./pages/Services/AddPolicyService")
);
const ViewPolicyService = React.lazy(() =>
  import("./pages/Services/ViewPolicyService")
);
const PremiumPoint = React.lazy(() => import("./pages/PremiumPoint"));
const ListPremiumPoint = React.lazy(() =>
  import("./pages/PremiumPoint/ListPremiumPoint")
);
const ImportPremiumPoint = React.lazy(() =>
  import("./pages/PremiumPoint/ImportPremiumPoint")
);
const CurrentMonthDueImport = React.lazy(() =>
  import("./pages/PremiumPoint/CurrentMonthDueImport")
);
const CurrentMonthDueList = React.lazy(() =>
  import("./pages/PremiumPoint/CurrentMonthDueList")
);
const OutStandingDueImport = React.lazy(() =>
  import("./pages/PremiumPoint/OutStandingDueImport")
);
const OutstandingDueList = React.lazy(() =>
  import("./pages/PremiumPoint/OutstandingDueList")
);
const LapsedPolicyDueList = React.lazy(() =>
  import("./pages/PremiumPoint/LapsedPolicyDueList")
);
const SBPolicyImport = React.lazy(() =>
  import("./pages/PremiumPoint/SBPolicyImport")
);
const SBBonusList = React.lazy(() =>
  import("./pages/PremiumPoint/SBPolicyList")
);
const LICMaturityImport = React.lazy(() =>
  import("./pages/PremiumPoint/LICMaturityImport")
);
const LapsedPolicyDueImport = React.lazy(() =>
  import("./pages/PremiumPoint/LapsedPolicyDueImport")
);
const LICMaturityList = React.lazy(() =>
  import("./pages/PremiumPoint/LICMaturityList")
);
const PendingPolicyServices = React.lazy(() =>
  import("./pages/Services/PendingPolicyServices")
);
const AddCLIANewReference = React.lazy(() =>
  import("./pages/CliaTeam/AddCLIA")
);
const EditCLIAReference = React.lazy(() =>
  import("./pages/CliaTeam/EditCLIAReference")
);
const ListCLIAReference = React.lazy(() =>
  import("./pages/CliaTeam/ListCLIAReference")
);
const CLIAReferences = React.lazy(() => import("./pages/CliaTeam"));
const EditCLIAProspect = React.lazy(() =>
  import("./pages/CliaTeam/EditCLIAProspect")
);
const ListCLIAProspect = React.lazy(() =>
  import("./pages/CliaTeam/ListCLIAProspect")
);
const ListCLIAAgencyData = React.lazy(() =>
  import("./pages/CliaTeam/ListCLIAAgencyData")
);
const EditCLIAAgencyData = React.lazy(() =>
  import("./pages/CliaTeam/EditAgencyData")
);
const ImportByPastePremiumPoint = React.lazy(() =>
  import("./pages/PremiumPoint/ImportByPastePremiumPoint")
);
const ListCLIAFamilyMembers = React.lazy(() =>
  import("./pages/CliaTeam/ListFamilyMembers")
);
const CLIAConceptDiscussed = React.lazy(() =>
  import("./pages/CliaTeam/ConceptDiscussion")
);
const CLIAFollowupList = React.lazy(() =>
  import("./pages/CliaTeam/FollowupList")
);
const FamilyMembers = React.lazy(() => import("./pages/Family"));
const MBGDueList = React.lazy(() => import("./pages/CliaTeam/MBGDueList"));
const Followups = React.lazy(() => import("./pages/Followups"));
const ACBook = React.lazy(() => import("./pages/Account/ACBook"));
const ROLES = {
  STAFF: 1984,
  ADMIN: 5150,
};
export default function secureRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<JwtLogin />} />
        {/* protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path="/" element={<Main />}>
              <Route
                exact
                path="Followups"
                element={<Followups />}
                key="Followups"
              />
              <Route
                exact
                path="dashboard"
                element={<Analytics />}
                key="dashboard"
              />
              <Route
                exact
                path="Services"
                element={<Services />}
                key="Services"
              >
                <Route exact path="Bond" element={<Bond />} key="Bond" />
                <Route
                  exact
                  path="UpdateBondStatus/:UID"
                  element={<UpdateBondStatus />}
                  key="UpdateBondStatus"
                />
                <Route
                  exact
                  path="UpdateENachStatus/:UID"
                  element={<UpdateENachStatus />}
                  key="UpdateENachStatus"
                />
                <Route exact path="Enach" element={<ENach />} key="ENach" />
                <Route
                  exact
                  path="AddPolicyService"
                  element={<AddPolicyService />}
                  key="AddPolicyService"
                />
                <Route
                  exact
                  path="ViewPolicyService/:UID"
                  element={<ViewPolicyService />}
                  key="ViewPolicyService"
                />
                <Route
                  exact
                  path="MinorNomination"
                  element={<MinorNomination />}
                  key="MinorNomination"
                />
                <Route
                  exact
                  path="UpdateDAB/:UID"
                  element={<UpdateDAB />}
                  key="UpdateDAB"
                />
                <Route
                  exact
                  path="UpdateMinorNomination/:UID"
                  element={<UpdateNomination />}
                  key="UpdateMinorNomination"
                />
                <Route
                  exact
                  path="MinorDAB"
                  element={<MinorDAB />}
                  key="MinorDAB"
                />
                <Route
                  exact
                  path="PolicyServices"
                  element={<PolicyServices />}
                  key="PolicyServices"
                />
                <Route
                  exact
                  path="PendingPolicyServices"
                  element={<PendingPolicyServices />}
                  key="PendingPolicyServices"
                />
              </Route>
              <Route exact path="Users" element={<Users />} key="Users">
                <Route exact path="Add" element={<AddUser />} key="AddUsers" />
                <Route
                  exact
                  path="Disabled"
                  element={<DisabledUser />}
                  key="DisabledUsers"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewUser />}
                  key="ViewUsers"
                />
                <Route
                  exact
                  path="List"
                  element={<ListUsers />}
                  key="ViewUsers"
                />
              </Route>
              <Route
                exact
                path="DahiyaMitra"
                element={<DahiyaMitra />}
                key="DahiyaMitra"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddDahiyaMitra />}
                  key="AddDahiyaMitra"
                />
                <Route
                  exact
                  path="List"
                  element={<ListDahiyaMitra />}
                  key="ListDahiyaMitra"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedDahiyaMitra />}
                  key="DeletedDahiyaMitra"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewDahiyaMitra />}
                  key="ViewDahiyaMitra"
                />
              </Route>

              <Route
                exact
                path="AccountBook"
                element={<ACBook />}
                key="AccountBook"
              />

              <Route exact path="Policy" element={<Policy />} key="Policy">
                <Route
                  exact
                  path="Add"
                  element={<AddPolicy />}
                  key="AddPolicy"
                />
                <Route
                  exact
                  path="List"
                  element={<ListPolicy />}
                  key="listpolicy"
                />
                <Route
                  exact
                  path="ListPendingUpdatePolicy"
                  element={<ListPendingUpdatePolicy />}
                  key="ListPendingUpdatePolicy"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedPolicy />}
                  key="DeletedPolicy"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<EditPolicy />}
                  key="EditPolicy"
                />
              </Route>
              <Route
                exact
                path="CurrentMonthDueImport"
                element={<CurrentMonthDueImport />}
                key="CurrentMonthDueImport"
              />
              <Route
                exact
                path="CurrentMonthDueList"
                element={<CurrentMonthDueList />}
                key="CurrentMonthDueList"
              />
              <Route
                exact
                path="OutstandingDueImport"
                element={<OutStandingDueImport />}
                key="OutstandingDueImport"
              />
              <Route
                exact
                path="OutstandingDueList"
                element={<OutstandingDueList />}
                key="OutstandingDueList"
              />
              <Route
                exact
                path="LapsedPolicyDueImport"
                element={<LapsedPolicyDueImport />}
                key="LapsedPolicyDueImport"
              />
              <Route
                exact
                path="LapsedPolicyDueList"
                element={<LapsedPolicyDueList />}
                key="LapsedPolicyDueList"
              />
              <Route
                exact
                path="SBPolicyImport"
                element={<SBPolicyImport />}
                key="SBPolicyImport"
              />
              <Route
                exact
                path="SBBonusList"
                element={<SBBonusList />}
                key="SBBonusList"
              />
              <Route
                exact
                path="LICMaturityImport"
                element={<LICMaturityImport />}
                key="LICMaturityImport"
              />
              <Route
                exact
                path="LICMaturityList"
                element={<LICMaturityList />}
                key="LICMaturityList"
              />
              <Route
                exact
                path="PremiumPoint"
                element={<PremiumPoint />}
                key="PremiumPoint"
              >
                <Route
                  exact
                  path="List"
                  element={<ListPremiumPoint />}
                  key="ListPremiumPoint"
                />
                <Route
                  exact
                  path="Import"
                  element={<ImportPremiumPoint />}
                  key="ImportPremiumPoint"
                />
                <Route
                  exact
                  path="ImportByPaste"
                  element={<ImportByPastePremiumPoint />}
                  key="ImportByPastePremiumPoint"
                />
              </Route>
              <Route exact path="CLIA" element={<CLIA />} key="CLIALogin">
                <Route exact path="Add" element={<AddCLIA />} key="AddCLIA" />
                <Route
                  exact
                  path="List"
                  element={<ListCLIA />}
                  key="ListCLIA"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedCLIA />}
                  key="DeletedCLIA"
                />
                <Route
                  exact
                  path="View/:UID"
                  element={<ViewCLIA />}
                  key="ViewCLIA"
                />
              </Route>

              <Route
                exact
                path="Customers"
                element={<Customers />}
                key="Customers"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddCustomer />}
                  key="AddCustomer"
                />
                <Route
                  exact
                  path="View"
                  element={<ViewCustomer />}
                  key="ViewCustomer"
                />
                <Route
                  exact
                  path="List"
                  element={<ListCustomer />}
                  key="ListCustomer"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedCustomer />}
                  key="DeletedCustomer"
                />
                <Route
                  exact
                  path="Import"
                  element={<ImportCustomer />}
                  key="ImportCustomer"
                />
                <Route
                  exact
                  path="List"
                  element={<ListCustomer />}
                  key="ListCustomer"
                />
              </Route>
              <Route
                exact
                path="Calendar"
                element={<CalendarView />}
                key="Calendar"
              />
              <Route
                exact
                path="Greeting"
                element={<Greetings />}
                key="Greeting"
              />
              <Route
                exact
                path="Folloups"
                element={<Followups />}
                key="Folloups"
              />
              <Route exact path="Inputs" element={<Inputs />} key="Inputs" />
              <Route
                exact
                path="SelfLICAgencyWork"
                element={<AgencyWorks />}
                key="SelfLICAgencyWork"
              />
              <Route
                exact
                path="FamilyMember/:Ref/:UID"
                element={<FamilyMembers />}
                key="FamilyMember"
              />
              <Route
                exact
                path="CLIAProspect"
                element={<CLIAReferences />}
                key="CLIAProspect"
              >
                <Route
                  exact
                  path="Add"
                  element={<AddCLIANewReference />}
                  key="CLIAProspect"
                />
                <Route
                  exact
                  path="CLIAFamilyMembers/:UID"
                  element={<ListCLIAFamilyMembers />}
                  key="CLIAFamilyMembers"
                />

                <Route
                  exact
                  path={`View/:UID`}
                  element={<ViewReference />}
                  key="View"
                />
                <Route
                  exact
                  path={`EditCLIAReference/:UID`}
                  element={<EditCLIAReference />}
                  key="EditCLIAReference"
                />
                <Route
                  exact
                  path={`EditCLIAProspect/:UID`}
                  element={<EditCLIAProspect />}
                  key="EditCLIAProspect"
                />
                <Route
                  exact
                  path={`EditCLIAAgencyData/:UID`}
                  element={<EditCLIAAgencyData />}
                  key="EditCLIAAgencyData"
                />
                <Route
                  exact
                  path="ListCLIAReference"
                  element={<ListCLIAReference />}
                  key="ListCLIAReference"
                />

                <Route
                  exact
                  path="ListCLIAProspect"
                  element={<ListCLIAProspect />}
                  key="ListCLIAProspect"
                />
                <Route
                  exact
                  path="ListCLIAAgencyData"
                  element={<ListCLIAAgencyData />}
                  key="ListCLIAAgencyData"
                />
                <Route
                  exact
                  path="ListCLIAMBGDueList"
                  element={<MBGDueList />}
                  key="ListCLIAMBGDueList"
                />

                <Route
                  exact
                  path="Import"
                  element={<ImportReference />}
                  key="Import"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedReference />}
                  key="DeletedReference"
                />
                <Route
                  exact
                  path="ConceptDiscussion/:UID"
                  element={<CLIAConceptDiscussed />}
                  key="CLIAConceptDiscussion"
                />
                <Route
                  exact
                  path="ProspectFollowup"
                  element={<CLIAFollowupList />}
                  key="CLIAProspectFollowup"
                />
              </Route>
              <Route
                exact
                path="Prospects"
                element={<Prospects />}
                key="Prospects"
              >
                <Route
                  exact
                  path="ListFamilyMembers/:UID"
                  element={<ListFamilyMembers />}
                  key="ListFamilyMembers"
                />
                <Route
                  exact
                  path="AddProspect"
                  element={<NewProspect />}
                  key="Prospects"
                />
                <Route
                  exact
                  path={`ViewProspect/:UID`}
                  element={<ViewProspect />}
                  key="ViewProspect"
                />
                <Route
                  exact
                  path="ListProspects"
                  element={<ListProspects />}
                  key="ListProspects"
                />
                <Route
                  exact
                  path="ConceptDiscussion/:UID"
                  element={<ConceptDiscussed />}
                  key="ConceptDiscussion"
                />
                <Route
                  exact
                  path="ProspectFollowup"
                  element={<FollowupList />}
                  key="ProspectFollowup"
                />

                <Route
                  exact
                  path="ImportProspects"
                  element={<ImportProspect />}
                  key="ImportProspects"
                />

                <Route
                  exact
                  path="Deleted"
                  element={<DeletedProspect />}
                  key="DeletedProspects"
                />
              </Route>
              <Route
                exact
                path="References"
                element={<References />}
                key="References"
              >
                <Route
                  exact
                  path="AddReference"
                  element={<NewReference />}
                  key="AddReference"
                />
                <Route
                  exact
                  path="ListFamilyMembers/:UID"
                  element={<ListFamilyMembers />}
                  key="ListFamilyMembers"
                />

                <Route
                  exact
                  path={`ViewReference/:UID`}
                  element={<ViewReference />}
                  key="ViewReference"
                />
                <Route
                  exact
                  path="ListReference"
                  element={<ListReference />}
                  key="ListReference"
                />

                <Route
                  exact
                  path="ImportReference"
                  element={<ImportReference />}
                  key="ImportReference"
                />
                <Route
                  exact
                  path="Deleted"
                  element={<DeletedReference />}
                  key="DeletedReference"
                />
              </Route>
            </Route>
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
